<template>
  <div class="toast-container">
    <toast-message-item
      v-for="(item, index) in items"
      :key="item.key"
      :data="item"
      :style="{'margin-bottom': calculateMargin(index)}"
    />
  </div>
</template>

<script>
// Import child components
const ToastMessageItem = () => import(/* webpackChunkName: "toast-message-item" */ "@/blocks/common/ToastMessageItem.vue")

// Export the SFC
export default {
  // Name of the component
  name: "ToastMessages",

  // Register children components
  components: {
    ToastMessageItem
  },

  // Define readonly data variables
  computed: {
    /**
     * Get all the toast items from Vuex store
     *
     * @returns {Array}
     */
    items() {
      return this.$store.getters["toasts/get"]
    }
  },

  // Define local method functions
  methods: {
    /**
     * Calculate the bottom margin to stack notifications on top of each other
     *
     * @param {Number} index
     * @returns {void}
     */
     calculateMargin(i) {
      return (i * 60) + 'px'
    },
  }
}
</script>
